<template src="./SystemDashboard.html"></template>
<style lang="scss">
@import "./SystemDashboard.scss";
</style>

<script>
import { defineComponent } from "vue";
import addClubAssociationModal from "../clubDetails/addClubAssociationModal/addClubAssociationModal.vue";
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "../common/AppLoader/AppLoader";
import { Input } from "@progress/kendo-vue-inputs";
import AppSnackBar from "../common/AppSnackBar/AppSnackBar";
import { addClubService } from "../../services/clubDetails/club.service";
import { Popup } from "@progress/kendo-vue-popup";
import { Avatar } from "@progress/kendo-vue-layout";
import vClickOutside from "click-outside-vue3";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";

export default defineComponent({
  name: "SystemDashboard",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    addClubAssociationModal,
    kbutton: Button,
    "app-loader": AppLoader,
    "k-input": Input,
    AppSnackBar,
    avatar: Avatar,
    Popup,
    AppPopUp,
  },
  data() {
    return {
      clubs: [],
      openAssociation: false,
      searchWord: "",
      toastMesg: "",
      notifyMessage: "Success",
      notifyType: "success",
      isNotify: false,
      loader: false,
      message: "",
      timer: null,
      searching: false,
      showSignOutPopup: false,
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      actionItem: [
        // {
        //     text: "My Profile",
        //     dropId: 1,
        // },
        {
          text: "Sign Out",
          dropId: 2,
        },
      ],
    };
  },
  mounted() {
    this.getClubs();
    this.getUser();
    this.$store.commit("SET_CLUB", {});
    this.$store.commit("SET_MENU_WITH_PERMISSION", {});
    this.$store.commit("SET_MENUE_LOADED", false);
  },
  computed: {
    clubList() {
      return this.$store.state.club.clubs;
    },
    clubListLoading() {
      return this.$store.state.club.loading;
    },
    modalVisible() {
      return this.$store.state.club.modalVisible;
    },
    assoModalVisible() {
      return this.$store.state.club.assoModalVisible;
    },
    isShowMsg() {
      return this.$store.state.common.showMsg;
    },
    showMsg() {
      return this.$store.state.common.status;
    },
    userDetails() {
      return this.$store.getters.userDetails;
    },
    user() {
      return this.$store.getters.userDetails.userPhotoPath;
    },
    isPlatformAdmin() {
      return this.$store.getters.userDetails.isPlatformAdmin;
    },
    fetchController() {
      return this.$store.state.club.fetchController;
    },
  },
  watch: {
    modalVisible(newVal) {
      this.toggleBodyScroll(newVal);
    },
    assoModalVisible(newVal) {
      this.toggleBodyScroll(newVal);
    },
  },
  methods: {
    actionHandler(type) {
      if (type == 2) {
        this.signOutHandler();
      }
    },
    signOutHandler() {
      if (configuration.enableIdentityServer) {
        auth.logout();
      }
      {
        localStorage.removeItem("clubeez_session");
        window.location.href = window.location.origin + "/login";
      }
    },
    toggleBodyScroll(isModalOpen) {
      if (isModalOpen) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
    GenerateNameInitials() {
      if (this?.userDetails?.firstName && this?.userDetails?.lastName) {
        return (
          this?.userDetails?.firstName?.charAt(0) +
          this?.userDetails?.lastName?.charAt(0)
        );
      } else if (this?.userDetails?.firstName) {
        return this?.userDetails?.firstName?.charAt(0);
      } else if (this?.userDetails?.lastName) {
        return this?.userDetails?.lastName?.charAt(0);
      } else {
        return "NA";
      }
    },
    clubNavigation(clubInfo) {
      window.localStorage.setItem("clubInfo", JSON.stringify(clubInfo));
      // this.$router.push(`/club-details/${clubInfo.id}/vouchers`);
      // return;
      if (clubInfo?.roleId === 2) {
        this.$router.push(`/club-details/${clubInfo.id}/vouchers`);
      } else {
        this.$router.push(`/club-details/${clubInfo.id}/dashboard`);
      }
    },
    getClubs() {
      this.$store.commit("LOADING_STATUS", true);
      let payload = {
        limit: 1000,
      };
      this.$store.dispatch("getClubs", payload);
    },
    getUser() {
      this.$store.dispatch("getUser");
    },
    openModal() {
      this.$store.commit("MODAL_VISIBLE", true);
    },
    openAssociationModal() {
      this.$store.commit("ASSO_MODAL_VISIBLE", true);
    },
    onSearch(e) {
      if (e.value == "") {
        this.searching = true;
      }
      this.$store.commit("LOADING_STATUS", true);
      this.searchWord = e.value;
      let payload = {
        SearchQuery: e.value,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getClubs", payload);
      }, 800);
    },
    onSearchCross() {
      this.searching = true;
      this.$store.commit("LOADING_STATUS", true);
      this.searchWord = "";
      let payload = {
        SearchQuery: this.searchWord,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("getClubs", payload);
      }, 800);
    },
    save(data) {
      this.loader = true;
      addClubService(data)
        .then((response) => {
          this.$store.commit("MODAL_VISIBLE", false);
          this.$store.commit("ASSO_MODAL_VISIBLE", false);
          this.loader = false;
          this.searchWord = "";
          this.message = "New club has been created successfully.";
          if (data.isAssociation == true) {
            this.message = "New association has been created successfully.";
          }

          let payload = {
            type: "success",
            Message: this.message,
          };
          this.$store.dispatch("showNotification", payload);
          let payloadGet = {
            limit: 1000,
          };
          this.$store.dispatch("getClubs", payloadGet);
        })
        .catch((error) => {
          this.$store.commit("SET_ADD_ERRORS", error.response.data.errors);
          this.loader = false;
        });
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
  },
});
</script>

<style></style>
