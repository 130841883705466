import Vuex from "vuex";
import { createStore } from "vuex";
import auth from "./auth/auth";
import systemDashboard from "./systemDashboard/systemDashboard";
import club from "./clubDetails/club";
import clubDashboard from "./clubDetails/clubDashboard";
import digitalPass from "./clubDetails/digitalPass";
import members from "./clubDetails/member";
import passTemplates from "./clubDetails/passEditor/passTemplate";
import manageRecipients from "./clubDetails/manageRecipients";
import user from "./auth/user/user";
import allAudience from "./clubDetails/allAudience";
import common from "./common/common";
import list from "./clubDetails/list";
import segments from "./clubDetails/segments";
import tag from "./clubDetails/tag";
import voucherLists from "./voucherDetails/voucherHomepage";
import voucherManageRecipients from "./voucherDetails/voucherManageRecipients";
import voucherReports from "./voucherDetails/voucherReport";
import commonEmailCampaign from "./clubDetails/emailCampaign/common";
import emailCampaignAnalytics from "./clubDetails/emailCampaign/emailCampaignAnalytics";
import emailCampaignAdvanceReport from "./clubDetails/emailCampaign/emailCampaignAdvanceReport";
import flowAutomation from "./clubDetails/emailCampaign/flowAutomation";
import listSegmentReport from "./clubDetails/listSegmentReport/listSegmentReport";

export default new Vuex.Store({
  modules: {
    auth,
    systemDashboard,
    club,
    members,
    digitalPass,
    passTemplates,
    manageRecipients,
    user,
    allAudience,
    common,
    list,
    tag,
    voucherLists,
    voucherManageRecipients,
    voucherReports,
    commonEmailCampaign,
    emailCampaignAnalytics,
    emailCampaignAdvanceReport,
    flowAutomation,
    segments,
    listSegmentReport
  },
});
