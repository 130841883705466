<template>
  <div>
    <ClubDetails v-if="appRender" />
    <Toaster ref="toaster" />
  </div>
</template>

<script>
import ClubDetails from "./components/clubDetails/ClubDetails.vue";
import AppLoader from "./components/common/AppLoader/AppLoader.vue";
export default {
  name: "App",
  components: {
    ClubDetails,
    AppLoader,
  },
  data: function () {
    return {
      appRender: false,
    };
  },
  computed: {
    isloading() {
      return this.$store.state.user.loading;
    },
  },
  watch: {
    $route(data) {
      this.appRender = true;
    },
  },
};
</script>

<style lang="scss">
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "./assets/scss/app.scss";
</style>
