import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";
import {
  getAllFields,
  importAudience,
  deleteAudience,
  getAudiences,
  getFieldById,
} from "../../services/clubDetails/allAudience.service";
import { DELETE, GET, POST } from "@/service";
import SnackBarModel from "@/models/snackBar/snackBarModel";

export type State = {
  isAddNewFieldModal: boolean;
  isUpdateFieldModal: boolean;
  isLoading: boolean;
  isLoadingModal: boolean;
  fields: any;
  fieldEdit: any;
  audiences: any;
  successStatus: object;
  errorStatus: object;
  importStatus: object;
  showErrorMsg: boolean;
  showSuccessMsg: boolean;
  showImportMsg: boolean;
  selectRecipientModal: boolean;
  campaignIdsEmailList: string;
  fetchController: "notfetched"; //Possible variable fetched , available , notavailable
};

const state: State = {
  isAddNewFieldModal: false,
  isUpdateFieldModal: false,
  isLoading: false,
  isLoadingModal: false,
  fields: [],
  fieldEdit: [],
  audiences: [],
  selectRecipientModal: false,
  successStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  errorStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  importStatus: {
    type: "",
    Class: "",
    Message: "",
  },
  showErrorMsg: false,
  showSuccessMsg: false,
  showImportMsg: false,
  campaignIdsEmailList: "",
  fetchController: "notfetched", //Possible variable fetched , available , notavailable
};

const allAudience = {
  state,
  getters: {},
  mutations: {
    SET_ALL_AUDIENCE(state: { audiences: any[] }, data: any) {
      state.audiences = data;
    },
    SET_CONFIGURE_FIELDS(state: { fields: any[] }, data: any) {
      state.fields = data;
    },
    SET_CONFIGURE_FIELD_EDIT(state: { fieldEdit: any[] }, data: any) {
      state.fieldEdit = data;
    },
    SET_ADD_NEW_FIELD_OPEN(state: { isAddNewFieldModal: boolean }, data: any) {
      state.isAddNewFieldModal = data;
    },
    SET_UPDATE_FIELD_OPEN(state: { isUpdateFieldModal: boolean }, data: any) {
      state.isUpdateFieldModal = data;
    },
    SET_LOADER_All_AUD(state: { isLoading: boolean }, data: any) {
      state.isLoading = data;
    },
    SET_LOADER_All_AUD_MODAL(state: { isLoadingModal: boolean }, data: any) {
      state.isLoadingModal = data;
    },

    SET_SUCCESS_STATUS(state: { successStatus: object }, data: any) {
      state.successStatus = data;
    },
    SET_ERROR_STATUS(state: { errorStatus: object }, data: any) {
      state.errorStatus = data;
    },

    SET_SUCCESS_MESSAGE_ISSHOW(state: { showSuccessMsg: boolean }, data: any) {
      state.showSuccessMsg = data;
    },
    SET_ERROR_MESSAGE_ISSHOW(state: { showErrorMsg: boolean }, data: any) {
      state.showErrorMsg = data;
    },

    SET_IMPORT_STATUS(state: { importStatus: object }, data: any) {
      state.importStatus = data;
    },
    SET_IMPORT_MESSAGE_ISSHOW(state: { showImportMsg: boolean }, data: any) {
      state.showImportMsg = data;
    },
    SET_UPDATE_FETCHCONTROLLER(state: { fetchController: string }, data: any) {
      state.fetchController = data;
    },

    SET_ALL_RECIPIENT_MODAL_OPEN(
      state: { selectRecipientModal: boolean },
      data: any
    ) {
      state.selectRecipientModal = data;
    },
    SET_CAMPAIGNIDS_EMAIL_LIST(
      state: { campaignIdsEmailList: string },
      data: any
    ) {
      state.campaignIdsEmailList = data;
    },
  },
  actions: {
    getAudience({ commit, dispatch }: any, model: GetAudienceModel) {
      // commit("SET_LOADER", true);
      commit("SET_LOADER_All_AUD", true);
      getAudiences(model)
        .then((response) => {
          commit("SET_ALL_AUDIENCE", response.data.data);
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          // commit("SET_LOADER", false);
          commit("SET_LOADER_All_AUD", false);
          if (response?.data?.data?.items?.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
          var payload = {
            clubId: model.clubId,
            entityType: model.entityType,
          };
          dispatch("getConfigureFields", payload);
        })
        .catch((error) => {
          // commit("SET_LOADER", false);
          commit("SET_LOADER_All_AUD", false);
        });
    },
    importAudience({ commit, dispatch }: any, payload: any) {
      importAudience(payload)
        .then((response) => {
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_ALL_AUDIENCE", response.data.data);
          commit("SET_LOADER_All_AUD_MODAL", false);
          commit("SET_LOADER", false);
          commit("SET_LOADER_All_AUD", false);
          commit("LOADING_STATUS", false);
          commit("SET_SHOW_MESSAGE", true);
          commit("IMPORT_MODAL_VISIBLE", false);
          commit("IMPORT_MODAL_VISIBLE", false);
          if (response?.data?.data?.items?.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
          payload.refetchGrid();
          var msg = new SnackBarModel();
          msg.type = "success";
          msg.Class = "status-error";
          msg.Message = "Data has been updated for all audiences.";

          commit("SET_IMPORT_STATUS", msg);

          commit("SET_IMPORT_MESSAGE_ISSHOW", true);
          setTimeout(() => {
            commit("SET_IMPORT_MESSAGE_ISSHOW", false);
          }, 4000);

          commit("MODAL_VISIBLE", false);
        })
        .catch((error) => {
          commit("LOADING_STATUS", false);
          commit("SET_LOADER_All_AUD_MODAL", false);
        });
    },
    getConfigureFields({ commit, dispatch }: any, payload: any) {
      // commit("SET_LOADER", true);
      commit("SET_LOADER_All_AUD", true);
      getAllFields(payload.clubId, payload.entityType)
        .then((response) => {
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_LOADER", false);
          commit("SET_LOADER_All_AUD", false);
          commit("SET_ADD_NEW_FIELD_OPEN", false);
          commit("SET_CONFIGURE_FIELDS", response.data.data);
          if (response?.data?.data?.items?.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
        })
        .catch((error) => {
          commit("SET_LOADER", false);
          commit("SET_LOADER_All_AUD", false);
        });
    },
    getAudienceById({ commit }: any, payload: number) {
      getFieldById(payload)
        .then((response) => {
          commit("SET_CONFIGURE_FIELD_EDIT", response.data.data);
        })
        .catch((error) => {});
    },
    deleteAudienceManager({ commit, dispatch }: any, payload: any) {
      deleteAudience(payload?.deletePayload)
        .then((response) => {
          commit("SET_UPDATE_FETCHCONTROLLER", "fetched");
          commit("SET_LOADER", false);
          commit("SET_LOADER_All_AUD", false);
          commit("SET_LOADER_All_AUD_MODAL", false);
          commit("MODAL_VISIBLE_COMMON", false);
          if (response?.data?.data?.items?.length) {
            commit("SET_UPDATE_FETCHCONTROLLER", "available");
          } else {
            commit("SET_UPDATE_FETCHCONTROLLER", "notavaiable");
          }
          dispatch("getAudience", payload?.refetchPayload);

          if (response.data?.data?.errorCount) {
            var model = new SnackBarModel();
            model.type = "error";
            model.Class = "status-error";
            model.Message = `Couldn't delete ${
              response.data?.data?.errorCount
            } members as they are in use by one or more ${
              payload.entityType == 1
                ? "templates."
                : payload.entityType == 2
                ? "campaigns."
                : "programs."
            }`;

            commit("SET_ERROR_STATUS", model);

            commit("SET_ERROR_MESSAGE_ISSHOW", true);
            setTimeout(() => {
              commit("SET_ERROR_MESSAGE_ISSHOW", false);
            }, 10000);
          }

          if (response.data?.data?.successCount) {
            var model = new SnackBarModel();
            model.type = "success";
            model.Class = "status-error";
            model.Message = `Successfully deleted ${
              response.data?.data?.successCount
            } members from the ${
              payload.entityType == 1
                ? "digital pass"
                : payload.entityType == 2
                ? "email campaign"
                : "voucher"
            } audience list.`;

            commit("SET_SUCCESS_STATUS", model);

            commit("SET_SUCCESS_MESSAGE_ISSHOW", true);
            setTimeout(() => {
              commit("SET_SUCCESS_MESSAGE_ISSHOW", false);
            }, 10000);
          }
        })
        .catch((error) => {
          commit("SET_LOADER", false);
          commit("SET_LOADER_All_AUD", false);
          commit("SET_LOADER_All_AUD_MODAL", false);
          commit("MODAL_VISIBLE_COMMON", false);
          dispatch("getAudience", payload?.refetchPayload);
          var modelMsg = new SnackBarModel();
          modelMsg.type = "error";
          modelMsg.Class = "status-error";
          modelMsg.Message =
            "Selected audience cannot be deleted as they are in use.";
          dispatch("showNotification", modelMsg);
        });
    },
  },
};
export default allAudience;
