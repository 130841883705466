<AppSnackBar
  :message="showMsg.Message"
  :notificationType="'success'"
  v-if="isShowMsg"
></AppSnackBar>
<app-loader v-if="clubListLoading"></app-loader>

<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>
<app-loader v-if="loader"></app-loader>

<div class="system-dashboard min-h-100vh d-flex justify-content-center">
  <div class="system-dashboard-box width-872 radius-8">
    <div class="d-flex justify-content-between align-items-center flex-row">
      <img src="../../assets/images/logo-blue.png" alt="Logo" />
      <div class="d-flex">
        <div class="d-flex avatar-dropdown-wrap">
          <div class="avater">
            <img class="size-40-fit" v-if="user" :src="user" alt="Logo" />
            <avatar
              :rounded="'full'"
              v-if="!user"
              :type="'icon'"
              style="margin-right: 5px"
            >
              {{GenerateNameInitials()}}
            </avatar>
          </div>
          <AppPopUp :buttonClass="'btn-clear p-0 w-14 h-14'" :class="'absolute p-0 w-14 h-14'" :items="actionItem"
              @dropId="actionHandler" :icon="'arrow-chevron-down'" :popUpSettings="popupSettings">
          </AppPopUp>
        </div>
      </div>
    </div>

    <div class="blank-space-md mb-1"></div>

    <div
      v-if="fetchController == 'available' || searchWord || (searching && clubListLoading)"
      class="d-flex justify-content-between align-items-end flex-row flex-wrap"
    >
      <div class="mb-3 mb-sm-0 w-100-vsm">
        <h1 class="font-28 font-22-sm font-w-300 mb-4">Clubs & Associations</h1>
        <div class="search-box-wrap width-220 w-100-vsm">
          <span v-if="!searchWord.length" class="k-icon k-i-zoom"></span>
          <span
            v-if="searchWord.length"
            @click="onSearchCross"
            class="k-icon k-i-close cursor-pointer"
          ></span>
          <k-input
            :class="'mb-0 w-100-vsm'"
            :icon="'zoom'"
            placeholder="Search"
            :value="searchWord"
            @input="onSearch"
          >
          </k-input>
        </div>
      </div>

      <div v-if="isPlatformAdmin" class="w-100-vsm mt-2 mt-sm-0">
        <kbutton
          :class="'me-3 mr-0-vsm mb-2 mb-sm-0 w-100-vsm'"
          :theme-color="'primary'"
          :fill-mode="'outline'"
          @click="openModal"
          >Add Club
        </kbutton>
        <kbutton
          :class="'w-100-vsm'"
          :theme-color="'primary'"
          @click="openAssociationModal"
          >Add Association</kbutton
        >
      </div>
    </div>

    <!--Empty state-->
    <div v-if="fetchController =='notavaiable'" class="club-list-wrap">
      <div
        class="d-flex justify-content-center align-items-center flex-column h-100"
      >
        <svg
          width="217"
          height="188"
          viewBox="0 0 217 188"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.05" cx="108" cy="92" r="92" fill="#253858" />
          <g filter="url(#filter0_bdd_3317_130255)">
            <rect
              x="31"
              y="24"
              width="154"
              height="62"
              rx="1.88497"
              fill="white"
              fill-opacity="0.12"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter1_bdd_3317_130255)">
            <rect
              x="27"
              y="17"
              width="162"
              height="65"
              rx="1.88497"
              fill="white"
              fill-opacity="0.22"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter2_dd_3317_130255)">
            <rect
              x="23"
              y="18"
              width="171"
              height="59"
              rx="3.69388"
              fill="white"
            />
          </g>
          <rect
            opacity="0.2"
            x="35"
            y="31"
            width="27.7041"
            height="27.7041"
            rx="13.8521"
            fill="#A4C3F3"
          />
          <rect x="73" y="35" width="51" height="7" rx="2" fill="#A4C3F3" />
          <rect
            opacity="0.5"
            x="73"
            y="49"
            width="104"
            height="5"
            rx="2.5"
            fill="#B9C8DF"
          />
          <rect
            opacity="0.5"
            x="73"
            y="59"
            width="94"
            height="5"
            rx="2.5"
            fill="#B9C8DF"
          />
          <mask
            id="mask0_3317_130255"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="35"
            y="31"
            width="28"
            height="28"
          >
            <rect
              x="35"
              y="31"
              width="27.7041"
              height="27.7041"
              rx="13.8521"
              fill="#A4C3F3"
            />
          </mask>
          <g mask="url(#mask0_3317_130255)">
            <path
              d="M44.3743 48.7427C45.9844 48.0877 47.5263 47.7602 49 47.7602C50.4737 47.7602 52.0019 48.0877 53.5848 48.7427C55.1949 49.3704 56 50.2027 56 51.2398C56 52.2119 55.2119 53 54.2398 53H43.7602C42.7881 53 42 52.2119 42 51.2398C42 50.2027 42.7914 49.3704 44.3743 48.7427ZM51.4561 44.9766C50.7739 45.6589 49.9552 46 49 46C48.0448 46 47.2261 45.6589 46.5439 44.9766C45.8616 44.2943 45.5205 43.4756 45.5205 42.5205C45.5205 41.5653 45.8616 40.7466 46.5439 40.0643C47.2261 39.3548 48.0448 39 49 39C49.9552 39 50.7739 39.3548 51.4561 40.0643C52.1384 40.7466 52.4795 41.5653 52.4795 42.5205C52.4795 43.4756 52.1384 44.2943 51.4561 44.9766Z"
              fill="#78A6ED"
            />
          </g>
          <g filter="url(#filter3_bdd_3317_130255)">
            <rect
              x="31"
              y="105"
              width="154"
              height="62"
              rx="1.88497"
              fill="white"
              fill-opacity="0.12"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter4_bdd_3317_130255)">
            <rect
              x="27"
              y="98"
              width="162"
              height="65"
              rx="1.88497"
              fill="white"
              fill-opacity="0.22"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter5_dd_3317_130255)">
            <rect
              x="23"
              y="99"
              width="171"
              height="59"
              rx="3.69388"
              fill="white"
            />
          </g>
          <rect
            opacity="0.2"
            x="35"
            y="112"
            width="27.7041"
            height="27.7041"
            rx="13.8521"
            fill="#A4C3F3"
          />
          <rect x="73" y="116" width="51" height="7" rx="2" fill="#A4C3F3" />
          <rect
            opacity="0.5"
            x="73"
            y="130"
            width="104"
            height="5"
            rx="2.5"
            fill="#B9C8DF"
          />
          <rect
            opacity="0.5"
            x="73"
            y="140"
            width="94"
            height="5"
            rx="2.5"
            fill="#B9C8DF"
          />
          <mask
            id="mask1_3317_130255"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="35"
            y="112"
            width="28"
            height="28"
          >
            <rect
              x="35"
              y="112"
              width="27.7041"
              height="27.7041"
              rx="13.8521"
              fill="#A4C3F3"
            />
          </mask>
          <g mask="url(#mask1_3317_130255)">
            <path
              d="M54.2383 123.012V122.238H52.7617V125.121C53.7461 124.77 54.2383 124.066 54.2383 123.012ZM45.2383 125.121V122.238H43.7617V123.012C43.7617 124.066 44.2539 124.77 45.2383 125.121ZM54.2383 120.762C54.6367 120.762 54.9883 120.914 55.293 121.219C55.5977 121.5 55.75 121.84 55.75 122.238V123.012C55.75 123.949 55.4336 124.77 54.8008 125.473C54.168 126.176 53.3828 126.586 52.4453 126.703C52.2344 127.219 51.8594 127.688 51.3203 128.109C50.8047 128.531 50.2773 128.801 49.7383 128.918V131.238H52.7617V132.75H45.2383V131.238H48.2617V128.918C47.7227 128.801 47.1836 128.531 46.6445 128.109C46.1289 127.688 45.7656 127.219 45.5547 126.703C44.6172 126.586 43.832 126.176 43.1992 125.473C42.5664 124.77 42.25 123.949 42.25 123.012V122.238C42.25 121.84 42.4023 121.5 42.707 121.219C43.0117 120.914 43.3633 120.762 43.7617 120.762H45.2383V119.25H52.7617V120.762H54.2383Z"
              fill="#78A6ED"
            />
          </g>
          <defs>
            <filter
              id="filter0_bdd_3317_130255"
              x="23.2051"
              y="16.2051"
              width="169.59"
              height="77.5898"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.76009" />
              <feGaussianBlur stdDeviation="1.76009" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.27 0 0 0 0 0.308571 0 0 0 0 0.45 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_backgroundBlur_3317_130255"
                result="effect2_dropShadow_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.89744" />
              <feGaussianBlur stdDeviation="1.94872" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_3317_130255"
                result="effect3_dropShadow_3317_130255"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_3317_130255"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_bdd_3317_130255"
              x="19.2051"
              y="9.20512"
              width="177.59"
              height="80.5898"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.76009" />
              <feGaussianBlur stdDeviation="1.76009" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.27 0 0 0 0 0.308571 0 0 0 0 0.45 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_backgroundBlur_3317_130255"
                result="effect2_dropShadow_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.89744" />
              <feGaussianBlur stdDeviation="1.94872" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_3317_130255"
                result="effect3_dropShadow_3317_130255"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_3317_130255"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_dd_3317_130255"
              x="0.8367"
              y="3.22447"
              width="215.327"
              height="103.327"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="7.38777" />
              <feGaussianBlur stdDeviation="11.0816" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.84694" />
              <feGaussianBlur stdDeviation="1.84694" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_3317_130255"
                result="effect2_dropShadow_3317_130255"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_3317_130255"
                result="shape"
              />
            </filter>
            <filter
              id="filter3_bdd_3317_130255"
              x="23.2051"
              y="97.2051"
              width="169.59"
              height="77.5898"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.76009" />
              <feGaussianBlur stdDeviation="1.76009" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.27 0 0 0 0 0.308571 0 0 0 0 0.45 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_backgroundBlur_3317_130255"
                result="effect2_dropShadow_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.89744" />
              <feGaussianBlur stdDeviation="1.94872" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_3317_130255"
                result="effect3_dropShadow_3317_130255"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_3317_130255"
                result="shape"
              />
            </filter>
            <filter
              id="filter4_bdd_3317_130255"
              x="19.2051"
              y="90.2051"
              width="177.59"
              height="80.5898"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.76009" />
              <feGaussianBlur stdDeviation="1.76009" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.27 0 0 0 0 0.308571 0 0 0 0 0.45 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_backgroundBlur_3317_130255"
                result="effect2_dropShadow_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="3.89744" />
              <feGaussianBlur stdDeviation="1.94872" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
              />
              <feBlend
                mode="normal"
                in2="effect2_dropShadow_3317_130255"
                result="effect3_dropShadow_3317_130255"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect3_dropShadow_3317_130255"
                result="shape"
              />
            </filter>
            <filter
              id="filter5_dd_3317_130255"
              x="0.8367"
              y="84.2245"
              width="215.327"
              height="103.327"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="7.38777" />
              <feGaussianBlur stdDeviation="11.0816" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3317_130255"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1.84694" />
              <feGaussianBlur stdDeviation="1.84694" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_dropShadow_3317_130255"
                result="effect2_dropShadow_3317_130255"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect2_dropShadow_3317_130255"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        <p class="font-19 color-light2 mt-32 mb-24">
          No clubs or associations found.
        </p>
        <div>
          <kbutton
            v-if=" isPlatformAdmin && !searchWord && !clubListLoading && fetchController == 'notavaiable'"
            :class="'me-3 px-5'"
            :theme-color="'primary'"
            :fill-mode="'outline'"
            @click="openModal"
            >Add Club
          </kbutton>
          <kbutton
            v-if=" isPlatformAdmin && !searchWord && !clubListLoading && fetchController == 'notavaiable'"
            :class="'px-4'"
            :theme-color="'primary'"
            @click="openAssociationModal"
            >Add Association
          </kbutton>
        </div>
      </div>
    </div>
    <!--end empty state-->

    <ul v-if="fetchController == 'available'" class="club-list-wrap">
      <li v-for="(club, index) in clubList" :key="index">
        <div
          @click="clubNavigation(club)"
          class="w-100 d-flex justify-content-between align-items-center p-sm-3 p-2 cursor-pointer"
        >
          <div class="d-flex align-items-center">
            <div class="img-thumb me-md-4 me-sm-3 me-2">
              <img v-if="club.logo" :src="club.logo.url" alt="Logo" />
              <img
                v-if="!club.logo"
                src="../../assets/images/club-img.png"
                alt="Logo"
              />
            </div>
            <div>
              <h2 class="font-19 font-16-sm text-color">{{club.name}}</h2>
              <span v-if="club.isAssociation" class="status-small-cheep pink"
                >Association</span
              >
              <span v-if="!club.isAssociation" class="status-small-cheep"
                >Club</span
              >

              <!--<kbutton v-if="club.isAssociation" :size="'null'" :theme-color="'primary'"-->
              <!--:class="'btn-small btn-hover-none btn-pink'" :fill-mode="'outline'">Association-->
              <!--</kbutton>-->
              <!--<kbutton v-if="!club.isAssociation" :size="'null'" :theme-color="'primary'"-->
              <!--:class="'btn-small btn-hover-none'" :fill-mode="'outline'">Club</kbutton>-->
            </div>
          </div>
          <kbutton :icon="'arrow-chevron-right'" :fill-mode="'flat'"></kbutton>
        </div>
      </li>
    </ul>

    <addClubAssociationModal
      v-if="modalVisible"
      @saveClubData="save($event)"
      :modalTitle="'Club'"
      :isAssociation="'false'"
    />

    <addClubAssociationModal
      v-if="assoModalVisible"
      @saveClubData="save($event)"
      :modalTitle="'Association'"
      :isAssociation="true"
    />
  </div>
</div>
