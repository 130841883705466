<template src="./addClubAssociationModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { Form } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels";
import AppLoader from "../../common/AppLoader/AppLoader";
import AddClubAndAssociationModel from "../../../models/club/AddClubAndAssociationModel";
import modal from "../../common/AppModal/AppModal";
const emailRegex = new RegExp(/\S+@\S+\.\S+/);

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    "k-form": Form,
    error: Error,
    "app-loader": AppLoader,
    modal,
  },

  props: {
    modalTitle: String,
    isAssociation: Boolean,
  },

  data: function () {
    var club = new AddClubAndAssociationModel();
    club.isAssociation = this.isAssociation;
    return {
      visibleDialog: true,
      clubNameValidationError: "",
      adminNameValidationError: "",
      emailValidationError: "",
      validationMessage: "",
      club,
    };
  },

  computed: {
    addClubErrors() {
      return this.$store.state.club.errorMessages
    },
    loading() {
      return this.$store.state.club.loading;
    },
    status() {
      return this.$store.state.club.status;
    },
    showMsg() {
      return this.$store.state.club.showMsg;
    },
    modalVisible() {
      return this.$store.state.club.modalVisible;
    },
    assoModalVisible() {
      return this.$store.state.club.assoModalVisible;
    },
  },

  methods: {
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    saveClub() {
      this.$emit("saveClubData", this.club);
    },
    cancelModal() {
      this.$store.commit("SET_ADD_ERRORS", []);
      this.$store.dispatch("closeModal");
      this.$store.dispatch("assoCloseModal");
    },
    modalHandler(data) {
      this.$store.commit("SET_ADD_ERRORS", []);
      this.$store.dispatch("closeModal");
      this.$store.dispatch("assoCloseModal");
    },
  },
};
</script>