<modal :title="`Add `+ modalTitle" :handler="modalVisible || assoModalVisible" :cssClass="'c-modal-small-2'"
  @modalHandler="modalHandler">
  <template v-slot:content>
    <div>
      <k-form>
        <div class="k-form-field">
          <label>{{modalTitle}} Name *</label>
          <k-input type="text" placeholder="Western Bulldogs" v-model="club.name" @keyup="addClubErrors.Name[0]=''">
          </k-input>
          <span class="k-form-error" :class="status.Class">{{this.addClubErrors?.Name?.[0]}}</span>
        </div>

        <div class="k-form-field">
          <label>{{modalTitle}} Admin Name</label>
          <k-input type="text" placeholder="John Doe" v-model="club.adminName" @keyup="addClubErrors.AdminName[0]=''">
          </k-input>
          <span class="k-form-error" :class="status.Class">{{this.addClubErrors?.AdminName?.[0]}}</span>
        </div>

        <div class="k-form-field">
          <label>{{modalTitle}} Admin Email *</label>
          <k-input type="text" placeholder="admin@xyz.com" v-model="club.adminEmail"
            @keyup="addClubErrors.AdminEmail[0]=''">
          </k-input>
          <span class="k-form-error" :class="status.Class">{{this.addClubErrors?.AdminEmail?.[0]}}</span>

          <error v-if="validationMessage!=''"> {{validationMessage}} </error>
        </div>

      </k-form>
    </div>
  </template>
  <template v-slot:footer>
    <app-loader v-if="loading" class="form-spinner"></app-loader>
    <p v-if="status.Message=='New club has been created successfully.' && showMsg" :class="'status.Class text-success'">
      {{status.Message}}
    </p>

    <kbutton @click="cancelModal" :class="'flex-none'">Cancel</kbutton>
    <kbutton :theme-color="'primary'" :class="'flex-none'" @click="saveClub"
      :disabled="club.name && club.adminEmail ? false : true">Save</kbutton>
  </template>
</modal>