export const clubeezBaseUrl = 'https://api.clubeez.com/' 

export const environment = <Config>{
    
    enableIdentityServer: true,

    identity: <IdentityConfig>{
        authority: 'https://identity.clubeez.com',
        clientId: 'vue-id-live',
        clientSecret: 'vue-secret-live',
        redirectUri: 'https://app.clubeez.com/authcallback',
        postLogoutRedirectUri: 'https://app.clubeez.com/authcallbacksignout'
    },

    apiBaseUrl: 'https://api.clubeez.com',
    appBaseUrl: 'https://app.clubeez.com'
}