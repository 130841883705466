<template src="./AppModal.html"></template>
<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import AppLoader from "../../common/AppLoader/AppLoader.vue";

export default {
  props: {
    handler: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    cssClass: {
      type: String,
      default: "modal-md",
    },
    footer: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "large",
    },
    type: {
      type: String,
      default: "converging-spinner",
    },
  },
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "app-loader": AppLoader,
  },
  data: function () {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("modalHandler", false);
    },
  },
};
</script>
