<template src="./LeftSideBar.html"></template>
<style></style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import AppPopUp from "../../common/AppPopUp/AppPopUp.vue";
import { Avatar } from "@progress/kendo-vue-layout";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import digitalPass from "@/store/clubDetails/digitalPass";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";

export default {
  name: "LeftSideBar",
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    AppPopUp,
    avatar: Avatar,
    AppLoader,
  },
  props: {
    id: {
      type: String,
    },
    clubId: {
      type: String,
    },
    sidebarToggleHandler: {
      type: Function,
    },
    resPonsiveSidebarOpen: {
      type: String,
    },
  },

  data() {
    return {
      clubInfo: "",
      userType: "",
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      createdClubId: this.clubId,
      value: { text: "...", dropId: 0 },
      profileDropdown: [
        { text: "...", dropId: 0 },
        { text: "My Profile", dropId: 1 },
        { text: "Log Out", dropId: 2 },
      ],

      profileDropdownitems: [
        {
          text: "My Profile",
          dropId: 1,
        },
        {
          text: "Sign Out",
          dropId: 2,
        },
      ],

      clickedComponentList: {
        dashboard: false,
        members: false,

        digitalPass: false,
        allDigitalPass: false,
        dpAudience: false,
        dpAllAudience: false,
        dpLists: false,
        dpTags: false,

        voucher: false,
        allVoucher: false,
        vAudience: false,
        vAllAudience: false,
        vLists: false,
        vSegments: false,

        emailCampaign: false,
        allCampaign: false,
        flowAutomation: false,
        ecAudience: false,
        ecAllAudience: false,
        ecLists: false,
        ecSegments: false,
        ecTags: false,
      },

      openEmailCampaign: false,
      openVoucher: false,
      openDigitalPass: false,
      openDPAudience: false,
      openECAudience: false,
      openVAudience: false,
    };
  },
  computed: {
    clubDetails() {
      return this.$store.state.club.club;
    },
    isClubLoading() {
      return this.$store.state.club.loading;
    },
    user() {
      return this.$store.getters.userDetails;
    },
    menuWithPermission() {
      return this.$store.state.common.menuWithPermission;
    },
    isMenuLoading() {
      return this.$store.state.common.isMenuLoading;
    },
  },
  watch: {
    $route() {
      this.click_component_toggle_handler_on_page_refresh();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.$store.dispatch("getClub", {
        clubId: this.$route.params.id,
        loadMenu: true,
      });
    }
  },
  mounted() {
    this.click_component_toggle_handler_on_page_refresh();
    let items = this.menuWithPermission;
    for (let item in items) {
      if (
        items[item].routerName != undefined &&
        items[item].routerName == this.$route.name
      ) {
        items[item].cssClass = "active";
      } else {
        items[item].cssClass = "";
      }
    }
    const clubInfo = JSON.parse(window.localStorage.getItem("clubInfo"));
    this.clubInfo = clubInfo;

    if ([0, 99]?.includes(clubInfo?.roleId)) {
      this.userType = "Platform Admin";
    }

    if (clubInfo?.roleId == 1 && !clubInfo?.isAssociation) {
      this.userType = "Club Admin";
    }

    if (clubInfo?.roleId == 1 && clubInfo?.isAssociation) {
      this.userType = "Association Admin";
    }

    if (clubInfo?.roleId === 2) {
      this.userType = "Report Viewer";
      // this.items = this.itemsForReportViewer;
    } else {
      // this.items = items;
    }
    this.getUser();
  },
  methods: {
    GenerateNameInitials() {
      if (this?.user?.firstName && this?.user?.lastName) {
        return (
          this?.user?.firstName?.charAt(0) + this?.user?.lastName?.charAt(0)
        );
      } else if (this?.user?.firstName) {
        return this?.user?.firstName?.charAt(0);
      } else if (this?.user?.lastName) {
        return this?.user?.lastName?.charAt(0);
      } else {
        return "NA";
      }
    },
    click_component_toggle_handler_on_page_refresh() {
      const url = this.$route.fullPath;
      const routeName = this.$route.name;
      // ;

      if (["dashboard", "settings"].find((value) => url?.includes(value))) {
        this.clickComponentToggleHandler("dashboard");
        return;
      }

      if (url?.includes("member-list")) {
        this.clickComponentToggleHandler("members");
        return;
      }

      if (url?.includes("email-log")) {
        this.clickComponentToggleHandler("emailLog");
        return;
      }

      if (
        ["DigitalPass", "CreatePass", "EditPass", "AddRecipients"].find(
          (value) => routeName?.includes(value)
        )
      ) {
        this.openDigitalPass = true;
        this.clickComponentToggleHandler("allDigitalPass");
        return;
      }

      if (
        ["digitalpass-allAudience", "configurefields"].find((value) =>
          url?.includes(value)
        ) &&
        this.$route.query.type == 1
      ) {
        this.openDPAudience = true;
        this.openDigitalPass = true;
        this.clickComponentToggleHandler("dpAllAudience");
        return;
      }

      if (url?.includes("lists") && this.$route.query.type == 1) {
        this.openDPAudience = true;
        this.openDigitalPass = true;
        this.clickComponentToggleHandler("dpLists");
        return;
      }

      if (
        ["segments", "segment/create", "segment/edit"].find((value) =>
          url?.includes(value)
        ) &&
        this.$route.query.type == 1
      ) {
        this.openDPAudience = true;
        this.openDigitalPass = true;
        this.clickComponentToggleHandler("dpSegments");
        return;
      }

      if (url?.includes("tags") && this.$route.query.type == 1) {
        this.openDPAudience = true;
        this.openDigitalPass = true;
        this.clickComponentToggleHandler("dpTags");
        return;
      }

      if (
        ["vouchers", "create-voucher", "edit-voucher", "/vouchers/report"].find(
          (value) => url?.includes(value)
        )
      ) {
        this.openVoucher = true;
        this.clickComponentToggleHandler("allVoucher");
        return;
      }

      if (
        ["allAudience", "configurefields"].find((value) =>
          url?.includes(value)
        ) &&
        this.$route.query.type == 3
      ) {
        this.openVoucher = true;
        this.openVAudience = true;
        this.clickComponentToggleHandler("vAllAudience");
        return;
      }
      if (url?.includes("lists") && this.$route.query.type == 3) {
        this.openVoucher = true;
        this.openVAudience = true;
        this.clickComponentToggleHandler("vLists");
        return;
      }

      if (
        ["segments", "segment/create", "segment/edit"].find((value) =>
          url?.includes(value)
        ) &&
        this.$route.query.type == 3
      ) {
        this.openVoucher = true;
        this.openVAudience = true;
        this.clickComponentToggleHandler("vSegments");
        return;
      }

      if (url?.includes("tags") && this.$route.query.type == 3) {
        this.openVoucher = true;
        this.openVAudience = true;
        this.clickComponentToggleHandler("vTags");
        return;
      }

      if (
        ["campaigns", "create-campaign", "edit-campaign"].find((value) =>
          url?.includes(value)
        )
      ) {
        this.openEmailCampaign = true;
        if (["from_flow_automation"].find((value) => url?.includes(value))) {
          this.clickComponentToggleHandler("flowAutomation");
          return;
        } else {
          this.clickComponentToggleHandler("allCampaign");
        }
        return;
      }

      if (
        ["flow-automation", "from_flow_automation"].find((value) =>
          url?.includes(value)
        )
      ) {
        this.openEmailCampaign = true;
        this.clickComponentToggleHandler("flowAutomation");
        return;
      }

      if (
        ["allAudience", "configurefields"].find((value) =>
          url?.includes(value)
        ) &&
        this.$route.query.type == 2
      ) {
        this.openEmailCampaign = true;
        this.openECAudience = true;
        this.clickComponentToggleHandler("ecAllAudience");
        return;
      }

      if (url?.includes("lists") && this.$route.query.type == 2) {
        this.openEmailCampaign = true;
        this.openECAudience = true;
        this.clickComponentToggleHandler("ecLists");
        return;
      }

      if (
        ["segments", "segment/create", "segment/edit"].find((value) =>
          url?.includes(value)
        ) &&
        this.$route.query.type == 2
      ) {
        this.openEmailCampaign = true;
        this.openECAudience = true;
        this.clickComponentToggleHandler("ecSegments");
        return;
      }

      if (url?.includes("tags") && this.$route.query.type == 2) {
        this.openEmailCampaign = true;
        this.openECAudience = true;
        this.clickComponentToggleHandler("ecTags");
        return;
      }

      this.clickComponentToggleHandler("");
    },

    clickComponentToggleHandler(itemCode) {
      const data = {
        dashboard: false,
        members: false,
        emailLog: false,

        digitalPass: false,
        allDigitalPass: false,
        dpAudience: false,
        dpAllAudience: false,
        dpLists: false,
        dpSegments: false,
        dpTags: false,
        voucher: false,

        allVoucher: false,
        vAudience: false,
        vAllAudience: false,
        vLists: false,
        vSegments: false,
        vTags: false,

        emailCampaign: false,
        allCampaign: false,
        ecAudience: false,
        ecAllAudience: false,
        ecLists: false,
        ecSegments: false,
        ecTags: false,
      };
      data[itemCode] = true;
      this.clickedComponentList = data;
    },

    redirectLogout() {
      if (configuration.enableIdentityServer) {
        auth.logout();
      }
      {
        localStorage.removeItem("clubeez_session");
        window.location.href = window.location.origin + "/login";
      }
    },

    onPress(item) {
      this.clickComponentToggleHandler(item.code);
      if (item.code == "digitalPass") {
        this.openDigitalPass = !this.openDigitalPass;
      }
      if (item.code == "emailLog") {
        this.$router.push("/club-details/" + this.id + "/email-log");
      }
      if (item.code == "emailCampaign") {
        this.openEmailCampaign = !this.openEmailCampaign;
      }
      if (item.code == "voucher") {
        this.openVoucher = !this.openVoucher;
      }

      if (item.code == "dashboard") {
        this.$router.push("/club-details/" + this.id + "/dashboard");
      }
      if (item.code == "members") {
        if (this.$route.name !== "Members") {
          this.$router.push("/club-details/" + this.id + "/member-list");
        }
      }

      if (item.code == "allDigitalPass") {
        this.$router.push({
          name: "DigitalPass",
          query: {
            type: "1",
          },
        });
      }
      if (item.code == "dpAudience") {
        this.openDPAudience = !this.openDPAudience;
      }
      if (item.code == "ecAudience") {
        this.openECAudience = !this.openECAudience;
      }
      if (item.code == "vAudience") {
        this.openVAudience = !this.openVAudience;
      }

      //Segment routes

      if (item.code == "allCampaign") {
        this.$router.push({
          name: "AllCampaign",
          query: {
            type: "2",
          },
        });
      }

      //Flow Automation routes

      if (item.code == "flowAutomation") {
        this.$router.push({
          name: "flowAutomation",
          query: {
            type: "2",
          },
        });
      }

      //Voucher routes

      if (item.code == "allVoucher") {
        this.$router.push({
          name: "AllVoucher",
          query: {
            type: "3",
          },
        });
      }

      if (item.code == "dpAllAudience") {
        this.$router.push({
          name: "DpAllAudience",
          query: {
            type: "1",
            key: Math.floor(Math.random() * 10 + 1),
          },
        });

        var model = new GetAudienceModel();
        model.clubId = this.$route.params.id;
        model.entityType = 1;
        model.searchQuery = "";
        model.limit = 100;
        this.$store.dispatch("getAudience", model);
      }
      if (item.code == "dpLists") {
        this.$router.push({
          name: "DpLists",
          query: {
            type: "1",
          },
        });
        var model = {
          clubId: this.$route.params.id,
          entityType: 1,
          searchQuery: " ",
        };
        this.$store.dispatch("getLists", model);
      }
      if (item.code == "dpSegments") {
        this.$router.push({
          name: "DpSegments",
          query: {
            type: "1",
          },
        });
      }
      if (item.code == "dpTags") {
        this.$router.push({
          name: "DpTags",
          query: {
            type: "1",
          },
        });
        var model = {
          clubId: this.$route.params.id,
          entityType: 1,
          searchQuery: " ",
        };
        this.$store.dispatch("getTags", model);
      }

      if (item.code == "ecAllAudience") {
        this.$router.push({
          name: "EcAllAudience",
          query: {
            type: "2",
            key: Math.floor(Math.random() * 10 + 1),
          },
        });

        var model = new GetAudienceModel();
        model.clubId = this.$route.params.id;
        model.entityType = 2;
        model.searchQuery = "";
        model.limit = 100;
        this.$store.dispatch("getAudience", model);
      }
      if (item.code == "ecLists") {
        this.$router.push({
          name: "EcLists",
          query: {
            type: "2",
          },
        });
        var model = {
          clubId: this.$route.params.id,
          entityType: 2,
          searchQuery: " ",
        };
        this.$store.dispatch("getLists", model);
      }
      if (item.code == "ecSegments") {
        this.$router.push({
          name: "EcSegments",
          query: {
            type: "2",
          },
        });
      }
      if (item.code == "ecTags") {
        this.$router.push({
          name: "EcTags",
          query: {
            type: "2",
          },
        });
        var model = {
          clubId: this.$route.params.id,
          entityType: 2,
          searchQuery: " ",
        };
        this.$store.dispatch("getTags", model);
      }

      if (item.code == "vAllAudience") {
        this.$router.push({
          name: "voucherAllAudience",
          query: {
            type: "3",
            key: Math.floor(Math.random() * 10 + 1),
          },
        });

        var model = new GetAudienceModel();
        model.clubId = this.$route.params.id;
        model.entityType = 3;
        model.searchQuery = "";
        model.limit = 100;
        this.$store.dispatch("getAudience", model);
      }
      if (item.code == "vLists") {
        this.$router.push({
          name: "voucherLists",
          query: {
            type: "3",
          },
        });
        var model = {
          clubId: this.$route.params.id,
          entityType: 3,
          searchQuery: " ",
        };
        this.$store.dispatch("getLists", model);
      }
      if (item.code == "vSegments") {
        this.$router.push({
          name: "voucherSegments",
          query: {
            type: "3",
          },
        });
      }
      if (item.code == "vTags") {
        this.$router.push({
          name: "voucherTags",
          query: {
            type: "3",
          },
        });
        var model = {
          clubId: this.$route.params.id,
          entityType: 3,
          searchQuery: " ",
        };
        this.$store.dispatch("getTags", model);
      }

      let items = this.menuWithPermission;
      for (let i in items) {
        if (
          items[i].text != undefined &&
          items[i].routerName == item.routerName
        ) {
          items[i].cssClass = "active";
        } else {
          items[i].cssClass = "";
        }
      }
      // this.items = items;
    },
    // Get club id
    getClubId() {
      this.$store.dispatch("getClub", this.$route.params.id);
      return this.$route.params.id;
    },
    getDropId(e) {
      if (e == 1) {
        this.$router.push(
          "/club-details/" + this.createdClubId + "/user-profile"
        );
      }
      if (e == 2) {
        this.redirectLogout();
      }
    },
    getUser() {
      this.$store.dispatch("getUser");
    },
  },
};
</script>
